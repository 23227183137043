<template>
  <div class="wrong-detail flex flex-wrap my-4 text-left" style="min-height: 660px;">
    <div class="lg:w-1/4 w-full mb-4">
      <div class="py-4 px-3 h-full rounded" style="background: #f2f2f6; font-size: 16px;">
        <div class="flex flex-wrap mb-2">
          <span>学科类型：</span>
          <div>{{ typeName(question.type) }}</div>
        </div>
        <div class="flex flex-wrap mb-2">
          <span>题目类型：</span>
          <div>{{ quesName(question.question_type) }}</div>
        </div>
        <div class="flex flex-wrap items-center mb-2">
          <span>难度：</span>
          <el-rate v-model="question.difficult" disabled></el-rate>
        </div>
        <div class="flex flex-wrap mb-2">
          <span>错误次数：</span>
          <span>{{ question.num }}</span>
        </div>
        <div class="flex flex-wrap mb-2">
          <span>最近答案：</span>
          <div v-if="question.answer">{{ question.answer }}</div>
          <div v-else><el-tag type="danger" size="mini" style="vertical-align: text-top;">未作答</el-tag></div>
        </div>
        <div class="flex flex-wrap mb-2">
          <span>标准答案：</span>
          <div class="text-gray-500" v-html="question.correct"></div>
        </div>
        <div class="flex flex-wrap mb-2">
          <span>答案解析：</span>
          <div v-if="question.analyze" v-html="question.analyze"></div>
          <div v-else>暂无解析</div>
        </div>
      </div>
    </div>
    <div class="lg:w-3/4 w-full px-4 mb-4 text-left">
      <div v-html="question.title" class="mb-3"></div>
      <!-- 选择题、判断题 -->
      <div v-if="[0, 1].includes(question.question_type) && question.items.length > 0">
        <el-radio-group v-model="question.answer" disabled class="flex flex-col">
          <el-radio
            v-for="(item, itemIndex) of question.items"
            :key="itemIndex"
            :label="item.prefix"
            class="mb-2 flex"
          >
            <div class="flex" style="font-size: 18px;">
              {{ item.prefix }}.
              <div v-html="item.content"></div>
            </div>
          </el-radio>
        </el-radio-group>
      </div>
      <!-- 多选题 -->
      <div v-else-if="question.question_type === 2 && question.items.length > 0">
        <el-checkbox-group
          disabled
          v-model="question.answer_array"
        >
          <el-checkbox
            v-for="(item, itemIndex) of question.items"
            :key="itemIndex"
            :label="item.prefix"
          >
            <div class="flex" style="font-size: 18px;">
              <span>{{ item.prefix }}. </span>
              <div v-html="item.content"></div>
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <!-- 求解题 -->
      <div v-else-if="question.question_type === 3 || question.question_type === 5">
        <div class="flex items-center mb-2" v-for="enter in question.enter_num" :key="enter">
          <span>（{{ enter }}）</span>
          <el-input v-model="question.answer_array[enter-1]"></el-input>
        </div>
      </div>
      <!-- 图形化编程题 -->
      <div v-else-if="question.question_type === 4 && question.type === 0">
        <el-button type="primary" icon="el-icon-view" @click="openScratch(question.project_id)">查看代码</el-button>
        <p class="my-2 px-3 py-2 bg-yellow-200 text-yellow-500 rounded" style="font-size: 16px;"><el-icon class="el-icon-info"></el-icon> 编程题暂不做评分，只提供做题思路，请联系指导教师进行订正</p>
      </div>
      <!-- C++ 或 Python -->
      <div v-else-if="question.question_type === 4 && [1,2].includes(question.type)">
        <span>填写代码：</span>
        <editor :answer="question.answer" :type="question.type" @update-answer="updateAnswer($event, question)"/>
        <p class="my-2 px-3 py-2 bg-yellow-200 text-yellow-500 rounded" style="font-size: 16px;"><el-icon class="el-icon-info"></el-icon> 编程题暂不做评分，只提供做题思路，请联系指导教师进行订正</p> 
      </div>
    </div>
  </div>
</template>

<script>
import { detail } from '@/api/wrong_set'

export default {
  name: 'WrongDetail',
  data() {
    return {
      question: {
        id: 0,
        title: ''
      },
      typeList: [
        { name: '全部', val: -1 },
        { name: 'Scratch', val: 0 },
        { name: 'C++', val: 1 },
        { name: 'Python', val: 2 },
        { name: 'NOIP', val: 3 },
        { name: '机器人', val: 4 },
      ],
      quesTypeList: [
        { name: '选择题', val: 0 },
        { name: '判断题', val: 1 },
        { name: '多选题', val: 2 },
        { name: '求解题', val: 3 },
        { name: '编程题', val: 4 },
        { name: '问答题', val: 5 }
      ],
    }
  },
  mounted() {
    this.question.id = this.$route.query.id
    this.init()
  },
  methods: {
    init() {
      detail({ id: this.question.id }).then(res => {
        this.question = res.data
      })
    },
    typeName(val) {
      return this.typeList.find(item => {
        return item.val === val
      })?.name
    },
    quesName(val) {
      return this.quesTypeList.find(item => {
        return item.val === val
      })?.name
    },
  }
}
</script>