import request from '@/utils/request'
const baseURL = '/exam.wrongset'

/**
 * 错题集
 * @param {*} params 
 * @returns 
 */
export function list(params) {
  return request({
    url: `${baseURL}/list`,
    method: 'get',
    params
  })
}
/**
 * 题目详情
 * @param {*} params 
 * @returns 
 */
 export function detail(params) {
  return request({
    url: `${baseURL}/detail`,
    method: 'get',
    params
  })
}
/**
 * 生成重做题目
 * @param {*} data 
 * @returns 
 */
 export function generateRetest(data) {
  return request({
    url: `${baseURL}/generateRetest`,
    method: 'post',
    data
  })
}